import { useState, useEffect, FC, ChangeEvent, useCallback } from "react";
import { Form, ListGroup, Button, DropdownButton } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getFormMessages, sendFormMessage } from "../api/bizworks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import useFullUserData from "../hooks/useFullUserData";

const MsgBtnStyled = styled(DropdownButton)`
  .dropdown-toggle::after {
    content: none;
  }
  button {
    border: none;
  }
  .btn:hover,
  .btn:focus-visible {
    background-color: transparent !important;
  }
  .btn:hover > svg,
  .btn:focus-visible > svg {
    color: #424649 !important;
  }
  & .dropdown-menu {
    margin-top: 1rem;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
`;

const MsgContainer = styled.div`
  width: 22rem;
  div {
    max-height: 200px;
  }
`;

interface MessageContainerProps {}

interface FormMessage {
  contactName: string;
  // eslint-disable-next-line camelcase
  created_at: string;
  createdAt?: string;
  detail: string;
  formId: number;
  id: number;
  read: boolean;
  source: string;
  tag: string;
}

const Message: FC<{ message: FormMessage }> = ({ message }) => {
  const data = useFullUserData({ id: (message as any)?.userId });
  return (
    <ListGroup.Item>
      <Form.Group className="py-2 overflow-auto message-list">
        <div className="d-flex justify-content-between mb-2">
          <div>
            <span className="fw-bold">
              {data?.displayName || message.contactName}
            </span>
            <br />
            <span className="text-muted">
              <small>{message.tag}</small>
            </span>
          </div>
          <span className="text-muted">
            <small>
              {message.createdAt &&
                new Date(message.createdAt).toLocaleString()}
            </small>
          </span>
        </div>
        <p className="text-wrap text-break mb-0">{message.detail}</p>
      </Form.Group>
    </ListGroup.Item>
  );
};

const MessageContainer: FC<MessageContainerProps> = () => {
  const { formId } = useParams();

  const [msgSent, setMsgSent] = useState("");
  const [msgSended, setMsgSended] = useState(false);
  const [messageList, setMessageList] = useState([]);

  const handleNewMessage = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setMsgSent(value);
  }, [setMsgSent]);

  useEffect(() => {
    (async () => {
      const formMessages = await getFormMessages(Number(formId));
      setMessageList(formMessages);
      setMsgSended(false);
    })();
  }, [formId, msgSended]);

  const sendMessage = useCallback(async () => {
    await sendFormMessage(Number(formId), msgSent);
    setMsgSended(true);
    setMsgSent("");
  }, [formId, setMsgSended, setMsgSent, msgSent]);

  return (
    <>
      <MsgBtnStyled
        autoClose="outside"
        align={{ lg: "end" }}
        id="dropdown-message-button"
        variant="transparent"
        title={
          <FontAwesomeIcon
            icon={faComments}
            size="xl"
            className="text-dark"
          />
        }
      >
        <MsgContainer id="msg-container">
          <h2 className="px-3 mt-1 h4">Messages:</h2>
          <ListGroup className="overflow-auto px-3 list-group-flush">
            {messageList.length !== 0 ?
              messageList.map((msg: FormMessage) => (
                <Message
                  key={msg.id}
                  message={msg}
                />
              )) :
              null}
          </ListGroup>
          <div className="p-3 border-top w-100 d-inline-flex">
            <Form.Control
              type="text"
              placeholder="Enter your message to the user..."
              value={msgSent}
              onChange={handleNewMessage}
            />
            <Button
              variant="transparent"
              onClick={() => sendMessage()}
              className="justify-content-end"
              disabled={msgSent.length === 0}
            >
              <FontAwesomeIcon
                icon={faPaperPlane}
                aria-hidden="true"
                className="text-dark"
                size="lg"
              />
            </Button>
          </div>
        </MsgContainer>
      </MsgBtnStyled>
    </>
  );
};

export default MessageContainer;
