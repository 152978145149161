import { FC, useCallback, useMemo } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { SortDropdown } from "@taxscribe/ui";
import styled from "styled-components";
import {
  faCalendarDays,
  faChevronDown,
  faFile,
  faPenToSquare,
  faRotate,
  faSort,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CustomDropdown from "./CustomDropdown";
import FilterBadge from "./FilterBadge";
import {
  Account,
  DropdownOptions,
  AssigneesWithDisplayName,
  FormSearchSort,
} from "../utils/Interfaces";
import useFormNameProcess from "../hooks/useProcessFormName";

const CustomFilterButton = styled(CustomDropdown)`
  .btn {
    border-color: #cdcdcd;
    font-weight: 600;
    color: #6e6d7a;
  }
  .button span {
    font-weight: 700 !important;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background: none;
    color: #212529 !important;
    font-weight: 700 !important;
  }

  .btn:hover {
    color: #515151;
    background: transparent;
    border: none;
    box-shadow: 0 0 0 4px rgb(173 40 120 / 10%);
  }
`;

const SortButton = styled(SortDropdown)`
  .btn {
    border-color: #cdcdcd;
    font-weight: 600;
    color: #6e6d7a;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background: none;
    color: #212529 !important;
    font-weight: 700 !important;
  }
  .btn:hover {
    color: #515151;
    background: transparent;
    border: none;
    box-shadow: 0 0 0 4px rgb(128 0 196 / 10%);
  }
`;

interface FormProps {
  id: number;
  name: string;
}

interface IFormType {
  id: number;
  name: string;
}

interface FilterContainerComponent {
  assignees: Array<AssigneesWithDisplayName>;
  statusFilter: string | null;
  setStatusFilter: Function;
  assignedToFilter: number;
  setAssignedToFilter: Function;
  taxYearFilter: number;
  setTaxYearFilter: Function;
  formFilter: number;
  setFormFilter: Function;
  syncedFilter: string | null;
  setSyncedFilter: Function;
  sortFilter: DropdownOptions;
  setSortFilter: (sort: FormSearchSort) => void;
  selectAll: Function;
  results: Array<Account>;
  clearFilters: Function;
  formTypes: Array<IFormType>;
}

const sortItems = [
  "Account Number",
  "Business Name",
  "Assignee",
  "Status",
  "Status Last Changed At",
];

const FilterContainer: FC<FilterContainerComponent> = ({
  assignees,
  statusFilter,
  setStatusFilter,
  assignedToFilter,
  setAssignedToFilter,
  taxYearFilter,
  setTaxYearFilter,
  formFilter,
  setFormFilter,
  syncedFilter,
  setSyncedFilter,
  sortFilter,
  setSortFilter,
  selectAll,
  results,
  clearFilters,
  formTypes,
}) => {
  const formNameProcess = useFormNameProcess();

  // Status Filter
  const onSelectStatusFilter = useCallback((
    key: FilterContainerComponent["statusFilter"],
  ) => {
    if (statusFilter !== key) {
      setStatusFilter(key);
      if (key !== "" && key !== "Approved") {
        setSyncedFilter("");
      }
    }
  }, [setStatusFilter, statusFilter, setSyncedFilter]);

  const statusFilterItems = useMemo(() => [
    {
      value: "",
      text: "All",
      active: statusFilter === "",
    },
    {
      value: "Approved",
      text: "Approved",
      active: statusFilter === "Approved",
    },
    {
      value: "Delivered",
      text: "Delivered",
      active: statusFilter === "Delivered",
    },
    {
      value: "Inadmissible",
      text: "Inadmissible",
      active: statusFilter === "Inadmissible",
    },
    {
      value: "In Review",
      text: "In Review",
      active: statusFilter === "In Review",
    },
    {
      value: "Returned",
      text: "Returned",
      active: statusFilter === "Returned",
    },
  ], [statusFilter]);

  // Form filter
  const onSelectFormFilter = useCallback((key: FilterContainerComponent["formFilter"]) => {
    if (formFilter !== key) {
      setFormFilter(key);
    }
  }, [formFilter, setFormFilter]);

  const formFilterItems = useMemo(() => formTypes.map((form: FormProps) => ({
    value: form.id,
    text: formNameProcess(form.name),
    active: Number(formFilter) === Number(form.id),
  })), [formFilter, formNameProcess, formTypes]);

  // Synced Filter
  const onSelectSyncedFilter = useCallback((
    key: FilterContainerComponent["syncedFilter"],
  ) => {
    if (syncedFilter !== key) {
      setSyncedFilter(key);
    }
  }, [syncedFilter, setSyncedFilter]);

  const filterOptions = useMemo(() => [
    { value: "Synced", text: "Synced", active: syncedFilter === "Synced" },
    {
      value: "Pending",
      text: "Not Synced",
      active: syncedFilter === "Pending",
    },
    { value: "", text: "Any", active: syncedFilter === "" },
  ], [syncedFilter]);

  const showSyncedFilter = !statusFilter || statusFilter === "Approved";

  // Tax year filter
  const onSelectTaxYearFilter = useCallback((
    key: FilterContainerComponent["taxYearFilter"],
  ) => {
    if (taxYearFilter !== key) {
      setTaxYearFilter(key);
    }
  }, [setTaxYearFilter, taxYearFilter]);

  const generateYears = useCallback((start: number, end: number) =>
    Array(end - start + 1)
      .fill(start)
      .map((year, index) => year + index), []);

  const taxYearFilterItems = useMemo(() => {
    const oldestYear = 2016;
    const nextYear = new Date().getFullYear() + 1;
    const yearArray: Array<number> = generateYears(oldestYear, nextYear);
    return yearArray.reverse().map(
      (year: number): DropdownOptions => ({
        value: year,
        text: year.toString(),
        active: Number(taxYearFilter) === year,
      }),
    );
  }, [generateYears, taxYearFilter]);


  // assigned to
  const onSelectAssignedToFilter = useCallback((
    key: FilterContainerComponent["assignedToFilter"],
  ) => {
    if (assignedToFilter !== key) {
      setAssignedToFilter(Number(key));
    }
  }, [assignedToFilter, setAssignedToFilter]);

  // assigned to filter is dinamic, based on api call for users
  // Unassigned -> value 0
  const assignedToFilterItems = useMemo(() => {
    const all = [{ value: -1, text: "All", active: assignedToFilter === -1 }];
    const unassigned = [
      { value: 0, text: "Unassigned", active: assignedToFilter === 0 },
    ];
    if (assignees.every((assignee) => !assignee.loginId)) {
      return [...all, ...unassigned];
    }

    const usersFromResults = assignees.map((assignee) => ({
      value: Number(assignee.loginId),
      text: (assignee.displayName || assignee.contactName || assignee.email),
      active: assignedToFilter === Number(assignee.loginId),
    }));

    return [...all, ...usersFromResults, ...unassigned];
  }, [assignees, assignedToFilter]);

  const currentFiltersSelected = useMemo(() => [
    { filter: "Status", value: statusFilter },
    { filter: "Assigned To", value: assignedToFilter },
    { filter: "Tax Year", value: taxYearFilter },
    { filter: "Form Type", value: formFilter },
    { filter: "Synced", value: syncedFilter },
    { filter: "Sort", value: sortFilter.text },
  ], [
    assignedToFilter,
    formFilter,
    sortFilter.text,
    statusFilter,
    syncedFilter,
    taxYearFilter,
  ]);

  return (
    <>
      <Row className="mt-2 mb-1 p-0">
        <Col className="d-flex align-items-end">
          <Button
            id="select-all-btn"
            size="sm"
            variant="outline-dark"
            onClick={() => selectAll()}
            disabled={!results.length}
          >
            Select All
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            id="clear-filters-btn"
            variant="link"
            className="text-dark align-bottom"
            onClick={() => clearFilters()}
            style={{ whiteSpace: "nowrap" }}
            size="sm"
          >
            Clear filters
          </Button>
          <CustomFilterButton
            id="statusFilter"
            text={
              <span>
                <FontAwesomeIcon
                  icon={faSquareCheck}
                  className="me-1"
                />{" "}
                Status{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="ms-1"
                />
              </span>
            }
            variant="outline-light"
            items={statusFilterItems}
            className="mx-1"
            onSelect={onSelectStatusFilter}
            size="sm"
          />
          <CustomFilterButton
            id="AssignedToFilter"
            text={
              <span>
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="me-1"
                />{" "}
                Assigned To{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="ms-1"
                />
              </span>
            }
            variant="outline-light"
            items={assignedToFilterItems}
            className="mx-1"
            onSelect={onSelectAssignedToFilter}
            size="sm"
          />
          <CustomFilterButton
            id="TaxYearFilter"
            text={
              <span>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  className="me-1"
                />{" "}
                Tax Year{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="ms-1"
                />
              </span>
            }
            variant="outline-light"
            items={taxYearFilterItems}
            className="mx-1"
            onSelect={onSelectTaxYearFilter}
            size="sm"
          />
          <CustomFilterButton
            id="FormFilter"
            text={
              <span>
                <FontAwesomeIcon
                  icon={faFile}
                  className="me-1"
                />{" "}
                Form{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="ms-1"
                />
              </span>
            }
            variant="outline-light"
            items={formFilterItems}
            className="mx-1"
            onSelect={onSelectFormFilter}
            size="sm"
          />
          {showSyncedFilter ? (
            <CustomFilterButton
              id="SyncedFilter"
              text={
                <span>
                  <FontAwesomeIcon
                    icon={faRotate}
                    className="me-1"
                  />{" "}
                  Synced{" "}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="ms-1"
                  />
                </span>
              }
              variant="outline-light"
              items={filterOptions}
              className="mx-1"
              onSelect={onSelectSyncedFilter}
              size="sm"
            />
          ) : null}
          <SortButton
            id="sortFilter"
            text={
              <span>
                Sort{" "}
                <FontAwesomeIcon
                  icon={faSort}
                  className="ms-1"
                />
              </span>
            }
            variant="outline-light"
            items={sortItems}
            className="mx-1"
            sortFilter={sortFilter}
            setSortFilter={setSortFilter}
            size="sm"
          />
        </Col>
      </Row>
      <Row className="mb-2 py-2">
        <Col className="d-flex align-items-center justify-content-lg-end overflow-scroll">
          <>
            <span className="me-2 fw-semibold">
              <small>Filtering by:</small>
            </span>
            {currentFiltersSelected.map((current: any, index: number) => (
              <div key={index}>
                <FilterBadge
                  filter={current.filter}
                  value={current.value}
                  assignedToFilterItems={assignedToFilterItems}
                  formFilterItems={formFilterItems}
                  syncedFilterItems={filterOptions}
                />
              </div>
            ))}
          </>
        </Col>
      </Row>
    </>
  );
};

export default FilterContainer;
