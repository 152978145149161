import { useMemo } from "react";
import useJurisdictionContext from "./useJurisdictionContext";
import useJurisdictionFormTypes from "./useJurisdictionFormTypes";

const fallbackDefault = "Listing";
const defaultFormTypes: Record<string, string> = {
  ga: "PT50P",
  nc: "Listing",
};
const useStateDefaultFormType = () => {
  const { jurisdiction: { stateAbbr } } = useJurisdictionContext();
  const { formTypes } = useJurisdictionFormTypes();

  const formType = useMemo(() => {
    return formTypes.find((ft) => {
      const lowerState = stateAbbr.toLowerCase();
      const stateFormType = defaultFormTypes[lowerState] || fallbackDefault;
      return ft.name === stateFormType;
    });
  }, [stateAbbr, formTypes]);

  return formType;
};

export default useStateDefaultFormType;
