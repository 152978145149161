import { useState, FC, ChangeEvent, useCallback, useMemo } from "react";
import { Form, Button, Nav, Navbar } from "react-bootstrap";
import { useAppToastContext, useAppModalContext } from "@taxscribe/ui";
import { AssigneesObj, Account } from "../utils/Interfaces";
import { updateAccountNumber } from "../api/bizworks";
import { Body, Footer } from "./Modal/AccountInformation";
import CustomSelector from "./CustomSelector";
import useUpdateAssignee from "../hooks/useUpdateAssignee";
import { useParams } from "react-router-dom";

interface AccountInformationComponent {
  account: Account;
  userList: Array<any>;
  currentUser: AssigneesObj;
  setUpdateValues: Function;
  refreshAccount: () => Promise<void>;
}

const AccountInformation: FC<AccountInformationComponent> = ({
  account,
  userList,
  currentUser,
  refreshAccount,
  setUpdateValues,
}) => {
  const { formId } = useParams();
  const { assignedToId } = account;
  const { addToastMessage } = useAppToastContext();
  const { addModal, removeModal } = useAppModalContext();

  const [accountNumber, setAccountNumber] = useState(
    account.accountNumberPrimary,
  );

  const closeModal = useCallback(() => {
    removeModal();
  }, [removeModal]);

  const [updateAssignee] = useUpdateAssignee();

  const showUpdateModal = useCallback(
    (userId: number) => {
      const modalProps = {
        header: "Assignee Update",
        body: <Body />,
        footer: (
          <Footer
            userId={userId}
            formId={formId}
            closeModal={closeModal}
            refreshAccount={refreshAccount}
            updateAssignedUser={updateAssignee}
          />
        ),
      };
      addModal(modalProps);
    },
    [addModal, closeModal, formId, refreshAccount, updateAssignee],
  );

  const handleAccountNumber = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setAccountNumber(value);
    },
    [setAccountNumber],
  );

  const isUpdateAvailable = useCallback(() => {
    const validStatus = account.syncStatus !== "accepted";
    const validUser = account.assignedToId === Number(currentUser?.loginId);
    return account && validStatus && validUser;
  }, [account, currentUser.loginId]);

  const syncOptions = useMemo(() => {
    const selectorOptions =
      account.syncStatus === "accepted" ?
        userList :
        userList.filter((userItem) => userItem.active);
    const unassigned = (
      <option
        key={0}
        value={0}
      >
        Unassigned
      </option>
    );
    const users = selectorOptions.map((userItem) => (
      <option
        key={userItem.contactName}
        value={userItem.loginId}
      >
        {userItem.displayName || userItem.contactName}
      </option>
    ));
    return [unassigned, ...users];
  }, [account.syncStatus, userList]);

  const handleAccountNumberUpdate = useCallback(async () => {
    if (!accountNumber) return;
    try {
      const response = await updateAccountNumber(account.formId, accountNumber);
      if (response.accountNumber) {
        addToastMessage({
          type: "success",
          header: "Updated",
          body: "The account number has been updated",
        });
      }
      setUpdateValues(true);
    } catch (err) {
      addToastMessage({
        type: "danger",
        header: "Failed",
        body: "The attempt to update the data has failed",
      });
    }
  }, [account.formId, accountNumber, addToastMessage, setUpdateValues]);

  const acctStatus = account?.status?.toLocaleLowerCase().replace(" ", "-");

  const isAssigneeDisabled = account.syncStatus === "accepted";

  return (
    <>
      <Navbar.Brand className="flex-grow-1">
        <h1 className="h4 ls-1 mb-1">{account ? account.businessName : ""}</h1>
        <p className="text-muted mb-0 fs-6">
          {account ? account.businessAddress : ""}
        </p>
      </Navbar.Brand>
      <Nav className="justify-content-end">
        <Nav.Item className="mb-lg-0 mb-3 align-items-end me-3">
          <CustomSelector
            label="Assignee:"
            options={syncOptions}
            selected={assignedToId}
            onChange={showUpdateModal}
            isDisabled={isAssigneeDisabled}
          />
        </Nav.Item>
        <Nav.Item className="d-flex flex-row align-items-end me-3 mb-lg-0 mb-3">
          <Form.Group className="me-2">
            <Form.Label className="fw-bold fs-6">Account Number:</Form.Label>
            <Form.Control
              type="text"
              id="acct-number-field"
              placeholder="Account Number"
              value={accountNumber}
              autoComplete="off"
              onChange={handleAccountNumber}
              disabled={!isUpdateAvailable()}
            />
          </Form.Group>
          <Button
            variant="dark"
            size="sm"
            onClick={handleAccountNumberUpdate}
            className="justify-content-lg-end mb-1"
            disabled={!isUpdateAvailable()}
          >
            Update
          </Button>
        </Nav.Item>
        <Nav.Item className="d-flex align-items-end me-3">
          <h6 className={`${acctStatus} text-uppercase mb-lg-0 p-3`}>
            {account && account.status}
          </h6>
        </Nav.Item>
        {account.syncStatus === "accepted" ? (
          <Nav.Item className="d-flex align-items-end me-3">
            <h6 className="synced text-uppercase mb-lg-0 p-3">Synced</h6>
          </Nav.Item>
        ) : null}
      </Nav>
    </>
  );
};

export default AccountInformation;
