import {
  useState,
  FC,
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Navbar, Nav, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppModalContext, useAppToastContext } from "@taxscribe/ui";

import { Account, AssigneesWithDisplayName } from "../utils/Interfaces";
import { Body, Footer } from "./Modal/CustomNavbar";
import BulkActions from "./BulkActions";
import {
  BodyConfirmation,
  FooterConfirmation,
} from "./Modal/CustomNavbar/confirmation";
import useFormNameProcess from "../hooks/useProcessFormName";

interface IFormType {
  id: number;
  name: string;
}

interface NavbarComponent {
  handleSearch: Function;
  handleExport: Function;
  assignees: Array<AssigneesWithDisplayName>;
  formType: number;
  checkedForms: Array<Account>;
  setCheckedForms: Function;
  formTypes: Array<IFormType>;
  bulkActionSelected: string | null;
  setBulkActionSelected: Function;
  statusFilter: string | null;
}

const CustomNavbar: FC<NavbarComponent> = ({
  assignees,
  handleSearch,
  handleExport,
  checkedForms,
  setCheckedForms,
  formType,
  formTypes,
  bulkActionSelected,
  setBulkActionSelected,
  statusFilter,
}) => {
  const { addToastMessage } = useAppToastContext();
  const formNameProcess = useFormNameProcess();
  const { addModal, removeModal } = useAppModalContext();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [bulkStatus, setBulkStatus] = useState("");
  const bulkUser = useRef(0);

  // Search
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setSearchValue(target.value);
  }, [setSearchValue]);

  // Assignees button values
  const assignedUsers = useMemo(() => assignees.map((val) => {
    return {
      value: val.loginId,
      active: false,
      show: val.active,
      text: val.displayName || val.contactName,
    };
  }), [assignees]);

  const getFormType = useCallback(() => {
    const selected = formTypes.find(
      (form: IFormType) => form.id === Number(formType),
    );
    return selected?.name || "";
  }, [formType, formTypes]);

  const closeModal = useCallback(() => {
    removeModal();
  }, [removeModal]);

  const showConfirmationModal = useCallback(
    (jobId: number) => {
      addModal({
        size: "lg",
        header: "Confirmation",
        body: <BodyConfirmation jobId={jobId} />,
        footer: (
          <FooterConfirmation
            closeModal={closeModal}
            navigate={navigate}
          />
        ),
      });
    },
    [addModal, closeModal, navigate],
  );

  const openBulkModal = useCallback((status: string) => {
    addModal({
      size: "lg",
      header: "Update summary",
      body: (
        <Body
          formType={getFormType()}
          checkedForms={checkedForms}
          setCheckedForms={setCheckedForms}
          bulkStatus={status}
          bulkUser={bulkUser.current}
          assignees={assignees}
          closeModal={closeModal}
          addToastMessage={addToastMessage}
          showConfirmationModal={showConfirmationModal}
          bulkActionSelected={bulkActionSelected}
          setBulkActionSelected={setBulkActionSelected}
        />
      ),
      footer: (
        <Footer
          formType={getFormType()}
          checkedForms={checkedForms}
          setCheckedForms={setCheckedForms}
          bulkStatus={status}
          bulkUser={bulkUser.current}
          assignees={assignees}
          closeModal={closeModal}
          addToastMessage={addToastMessage}
          showConfirmationModal={showConfirmationModal}
          bulkActionSelected={bulkActionSelected}
          setBulkActionSelected={setBulkActionSelected}
        />
      ),
    });
  }, [
    addModal,
    assignees,
    bulkActionSelected,
    addToastMessage,
    bulkUser,
    checkedForms,
    closeModal,
    getFormType,
    setBulkActionSelected,
    setCheckedForms,
    showConfirmationModal,
  ]);

  const onSelectAssigned = useCallback((eventKey: number) => {
    bulkUser.current = Number(eventKey);
    openBulkModal(bulkStatus);
  }, [openBulkModal, bulkStatus]);

  const onClickSetStatus = useCallback((status: string) => {
    setBulkStatus(status);
    openBulkModal(status);
  }, [openBulkModal]);

  const onKeyDownSearch = useCallback((
    event: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.code === "Enter") {
      handleSearch(searchValue);
    }
  }, [handleSearch, searchValue]);

  const formName = formNameProcess(getFormType());

  return (
    <Navbar
      id="header-nav"
      expand="lg"
      className="bg-white sticky-top border-bottom mb-3 flex-lg-wrap"
    >
      <Navbar.Brand className="text-uppercase ls-1">{formName}</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse
        id="navbarScroll"
        className="justify-content-start justify-content-lg-end"
      >
        <div className="d-flex my-3 my-lg-0">
          <Form.Control
            type="text"
            placeholder="Search Account Number, Name, or Address"
            className="me-2"
            aria-label="Search Account Number, Name, or Address"
            id="search-form"
            value={searchValue}
            onChange={handleChange}
            autoComplete="off"
            onKeyDown={onKeyDownSearch}
          />
          <Button
            variant="dark"
            type="button"
            onClick={() => handleSearch(searchValue)}
          >
            Search
          </Button>
        </div>
        <Nav className="my-2 my-lg-0 ms-lg-3">
          <Button
            variant="dark"
            onClick={() => handleExport()}
          >
            Export
          </Button>
        </Nav>
        <BulkActions
          formType={getFormType()}
          checkedForms={checkedForms}
          assignActionProps={{
            assignedUsers,
            onSelectAssigned,
          }}
          changeStatusActionProps={{
            onClick: onClickSetStatus,
          }}
          bulkActionSelected={bulkActionSelected}
          setBulkActionSelected={setBulkActionSelected}
          statusFilter={statusFilter}
        />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
