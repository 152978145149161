import { useState, useEffect, FC, useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";

import Results from "./Results";
import CustomNavbar from "../../CustomNavbar";
import FormsTable from "./FormsTable";
import FormsTableFooter from "./FormsTableFooter";
import FilterContainer from "../../FilterContainer";

import useFormNameProcess from "../../../hooks/useProcessFormName";
import useUserListContext from "../../../hooks/useUserListContext";
import useJurisdictionFormTypes from "../../../hooks/useJurisdictionFormTypes";
import { getForms } from "../../../api/bizworks";
import { trimString, formatDate, exportToCSV } from "../../../utils";
import { validateCheckmark } from "../../BulkActions/validations";
import { Account, formWithJobRelated } from "../../../utils/Interfaces";
import { useHydration } from "@taxscribe/ui";
import { camelCase } from "lodash";
import TotalCount from "./TotalCount";
import useStateDefaultFormType from "../../../hooks/useStateDefaultFormType";

const currentYear = new Date().getFullYear();
const defaultFilters = {
  status: "Delivered",
  assignedTo: 0,
  taxYear: currentYear,
  form: 0,
  synced: "",
  sort: { text: "", value: "", active: true },
};

const useStateAndReset = (
  initialState: any,
  setPage: (page: number) => void,
) => {
  const [state, setState] = useState(initialState);
  const setAndResetPage = useCallback(
    (val: any) => {
      setPage(1);
      setState(val);
    },
    [setPage, setState],
  );
  return [state, setAndResetPage];
};

// TODO: Refactor this entire component to be less like spaghetti...
const Forms: FC = () => {
  const defaultFormType = useStateDefaultFormType();
  const { userList } = useUserListContext();
  const { formTypes } = useJurisdictionFormTypes();
  const formNameProcess = useFormNameProcess();

  const { persist, data: filters } = useHydration("formSearchFilters");
  const filtersWithDefaults = useMemo(
    () => ({
      ...defaultFilters,
      ...(filters || {}),
    }),
    [filters],
  );

  const [page, setPage] = useState(1);
  const [search, setSearch] = useStateAndReset("", setPage);
  const [status, setStatus] = useStateAndReset(filters?.status, setPage);
  const [assignedTo, setAssignedTo] = useStateAndReset(
    filtersWithDefaults.assignedTo,
    setPage,
  );
  const [taxYear, setTaxYear] = useStateAndReset(
    filtersWithDefaults.taxYear,
    setPage,
  );
  const [form, setForm] = useStateAndReset(filtersWithDefaults.form, setPage);
  const [synced, setSynced] = useStateAndReset(
    filtersWithDefaults.synced,
    setPage,
  );
  const [sortCriteria, setSortCriteria] = useStateAndReset(
    filtersWithDefaults.sort,
    setPage,
  );

  const [results, setResults] = useState<Array<Account>>([]);
  const [count, setCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [currentPageLimit, setCurrentPageLimit] = useState(100);
  const [checkedForms, setCheckedForms] = useState<Array<Account>>([]);
  const [formsWaitingUpdate, setFormsWaitingUpdate] = useState<
    Array<formWithJobRelated>
  >([]);
  const [bulkActionSelected, setBulkActionSelected] = useState(null);

  const handleSortType = useCallback(
    (aStatus: string, bStatus: string) => {
      setPage(1);
      const correctOrder = [
        "Approved",
        "Delivered",
        "Inadmissible",
        "In Review",
        "Returned",
      ];
      if (sortCriteria.value === "ASC") {
        return correctOrder.indexOf(aStatus) - correctOrder.indexOf(bStatus);
      }
      return correctOrder.indexOf(bStatus) - correctOrder.indexOf(aStatus);
    },
    [setPage, sortCriteria.value],
  );

  const handleStatusSort = useCallback(
    (data: Account[]) => {
      setPage(1);
      return data.sort((a: Account, b: Account) =>
        handleSortType(a["status"], b["status"]),
      );
    },
    [handleSortType, setPage],
  );

  const handleSearch = useCallback(
    (searchValue: string) => {
      setSearch(searchValue);
      setPage(1);
    },
    [setSearch, setPage],
  );

  const handleDataExport = useCallback(() => {
    const headers = [
      "Account Number",
      "Business Name",
      "Business Address",
      "Business City",
      "Jurisdiction",
      "Assigned To",
      "Tax Year",
      "Status",
      "Status Changed At",
      "Submitted At",
    ];
    const data = results.map((result: Account) => ({
      accountNumber: result.accountNumberPrimary,
      businessName: result.businessName,
      businessAddress: result.businessAddress,
      businessCity: result.businessCity,
      jurisdictionName: result.jurisdictionName,
      assignedToName: result.assignedToName,
      taxYear: result.taxYear,
      status: result.status,
      statusChangedAt: formatDate(result.statusChangedAt),
      submittedAt: formatDate(result.submittedAt),
    }));
    exportToCSV("Export.csv", headers, data);
  }, [results]);

  const selectAll = useCallback(() => {
    const prevResults = [...results];
    const allForms = prevResults.filter((result: any) => {
      const {
        formId,
        formType,
        status,
        accountNumberPrimary,
        assignedToName,
        syncStatus,
      } = result;
      const { isDisabled } = validateCheckmark({
        formId,
        formType,
        status,
        formsWaitingUpdate,
        bulkActionSelected,
        accountNum: accountNumberPrimary ?
          trimString(accountNumberPrimary) :
          "",
        assignedToName: assignedToName ? trimString(assignedToName) : "",
        syncStatus,
        formNameProcess,
      });
      return !isDisabled;
    });
    if (allForms.length === checkedForms.length) {
      setCheckedForms([]);
    } else {
      setCheckedForms(allForms);
    }
  }, [
    bulkActionSelected,
    checkedForms.length,
    formNameProcess,
    formsWaitingUpdate,
    results,
  ]);

  const resetFormTypeFilter = useCallback(() => {
    if (defaultFormType && formTypes.length !== 0) {
      setForm(defaultFormType.id);
    }
  }, [defaultFormType, setForm, formTypes]);

  const clearFilters = useCallback(() => {
    setStatus(defaultFilters.status);
    setAssignedTo(defaultFilters.assignedTo);
    setTaxYear(defaultFilters.taxYear);
    resetFormTypeFilter();
    setSynced(defaultFilters.synced);
    setSortCriteria(defaultFilters.sort);
    setPage(1);
  }, [
    setStatus,
    setAssignedTo,
    setTaxYear,
    setSynced,
    setPage,
    setSortCriteria,
    resetFormTypeFilter,
  ]);

  useEffect(() => {
    persist({
      taxYear,
      assignedTo,
      status,
      form,
      synced,
      search,
      currentPageLimit,
    });
  }, [
    persist,
    taxYear,
    assignedTo,
    status,
    form,
    synced,
    search,
    currentPageLimit,
  ]);

  useEffect(() => {
    setLoading(true);
    const filters = {
      taxYear,
      assignedTo,
      status,
      form,
      synced,
      search,
      currentPageLimit,
    };
    const sortKey = camelCase(sortCriteria.text || "");
    const sort = {
      [sortKey]: sortCriteria?.value,
    };

    // Debounce this so it doesn't DDOS the server
    const activeSearch = window.setTimeout(async () => {
      getForms({ filters, sort, page })
        .then(({ formsData, formsOnQueue, total }) => {
          setCount(total);
          setResults(
            sortCriteria.text === "Status" ?
              handleStatusSort(formsData) :
              formsData,
          );
          setFormsWaitingUpdate(formsOnQueue);
          setCheckedForms([]);
          if (form === 0) resetFormTypeFilter();
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }, 300);

    return () => {
      window.clearTimeout(activeSearch);
    };
  }, [
    search,
    status,
    assignedTo,
    taxYear,
    form,
    page,
    synced,
    sortCriteria,
    currentPageLimit,
    handleStatusSort,
    bulkActionSelected,
    resetFormTypeFilter,
  ]);

  return (
    <>
      <CustomNavbar
        assignees={userList}
        handleSearch={handleSearch}
        handleExport={handleDataExport}
        formType={form}
        checkedForms={checkedForms}
        setCheckedForms={setCheckedForms}
        formTypes={formTypes}
        bulkActionSelected={bulkActionSelected}
        setBulkActionSelected={setBulkActionSelected}
        statusFilter={status}
      />
      <FilterContainer
        assignees={userList}
        statusFilter={status}
        setStatusFilter={setStatus}
        assignedToFilter={assignedTo}
        setAssignedToFilter={setAssignedTo}
        taxYearFilter={taxYear}
        setTaxYearFilter={setTaxYear}
        formFilter={form}
        setFormFilter={setForm}
        syncedFilter={synced}
        setSyncedFilter={setSynced}
        sortFilter={sortCriteria}
        setSortFilter={setSortCriteria}
        selectAll={selectAll}
        results={results}
        clearFilters={clearFilters}
        formTypes={formTypes}
      />
      <Row>
        <Col>
          <Results
            results={results}
            isLoading={isLoading}
          >
            <>
              <TotalCount
                limit={currentPageLimit}
                count={count}
              />
              <FormsTable
                results={results}
                checkedForms={checkedForms}
                setCheckedForms={setCheckedForms}
                formsWaitingUpdate={formsWaitingUpdate}
                bulkActionSelected={bulkActionSelected}
              />
              <FormsTableFooter
                page={page}
                count={count}
                selectPage={setPage}
                limit={currentPageLimit}
                setLimit={setCurrentPageLimit}
              />
            </>
          </Results>
        </Col>
      </Row>
    </>
  );
};

export default Forms;
